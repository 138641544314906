<template>
  <header class="wow animated slideInUp" />
  <l-bar class="l-bar wow animated slideInUp" />
  <section class="shi-yong wow animated slideInUp">
    <section class="shi-yong-main">
      <p class="ru-he">如何使用立享花</p>
      <div class="flex-sp">
        <img src="./images/img_ruhe_01.png" class="shi-yong-img">
        <div class="shi-yong-content">
          <img src="./images/01.png" class="shi-yong-number">
          <p class="wide">选择商户</p>
          <p class="pt3" v-html="content[0]" />
        </div>
      </div>
      <div class="flex-sp">
        <div class="shi-yong-content">
          <img src="./images/02.png" class="shi-yong-number pt80">
          <p class="wide">挑选商品</p>
          <p v-html="content[1]" />
        </div>
        <img src="./images/img_ruhe_02.png" class="shi-yong-img">
      </div>
      <div class="flex-sp">
        <img src="./images/img_ruhe_03.png" class="shi-yong-img">
        <div class="shi-yong-content">
          <img src="./images/03.png" class="shi-yong-number">
          <p class="wide">申请订单</p>
          <p v-html="content[2]" />
        </div>
      </div>
      <div class="flex-sp">
        <div class="shi-yong-content">
          <img src="./images/04.png" class="shi-yong-number">
          <p class="wide">支付订单</p>
          <p v-html="content[3]" />
        </div>
        <img src="./images/img_ruhe_04.png" class="shi-yong-img">
      </div>
    </section>
  </section>
  <section class="wen-ti wow animated slideInUp">
    <p class="wen-ti-title">常见问题</p>
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="会不会收取额外费用？" name="1">
        <div class="collapse">
          「立享花」是0息0费，在每月按时还款的情况下，不会产生任何费用。
        </div>
      </el-collapse-item>
      <el-collapse-item title="是否会影响我的信用评级？" name="2">
        <div class="collapse">
          在「立享花」中只有逾期将会使您的信用评级下降，影响再次使用。
        </div>
      </el-collapse-item>
      <el-collapse-item title="申请条件是什么？" name="3">
        <div class="collapse">
          22-55周岁均可申请，无不良记录，不限消费水平。
        </div>
      </el-collapse-item>
      <el-collapse-item title="是否有安全保障？" name="4">
        <div class="collapse">
          「立享花」是领创集团亚太地区场景科技服务产品，安全可靠，保证您的隐私绝对不会被泄露！
        </div>
      </el-collapse-item>
    </el-collapse>
  </section>
</template>

<script setup>
import LBar from './component/l-bar'
import { WOW } from 'wowjs'
import { onMounted } from 'vue'
onMounted(() => {
  new WOW().init()
})
const content = [`「立享花」已与众多品牌商户达成合作，<br/>我们挑选值得信赖的品牌给您最满意的选<br/>择。`, `挑选心仪的商品，花相同的钱，享受不一<br/>样的购物体验，「立享花」让您的选购无<br/>后顾之忧。`, `扫描商户付款二维码，申请订单只需两<br/>步，极速审核，即刻通过。`, `完成订单后，灵活支付分期，按照自己的节奏<br/>安排资金流。`]
</script>

<style lang='less' scoped>
.flex-sp{
  display:flex;
  justify-content: space-between;
}
.pt80{
  padding-top:80px !important;
}
p{
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  color: #000000;
  margin:10px 0;
}
span{
  font-size: 24px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #191919;
}
header{
  width:100%;
  height:700px;
  background:url('./images/bg_02.png');
  background-size:100% 100%;
  margin-top:-90px;
  margin-bottom:-90px;
}
.l-bar{
  margin-top:150px;
}
.shi-yong{
  background: #ecf2fe;
  width:100%;
  .shi-yong-main{
    width: 1040px;
    margin:169px auto 0;
    padding:130px 0 119px;
    .pt3{
      padding-top:3px;
    }
    .ru-he{
      text-align:center;
      font-size: 50px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #000000;
      margin-bottom:172px;
    }
    .shi-yong-img{
      width:520px;
      height:520px;
    }
    p{
      font-size: 24px;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: #191919;
      line-height: 33px;
    }
    .shi-yong-number{
        margin-top:21px;
    }
    .wide{
      font-size: 40px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #191919;
    }
  }
}
.wen-ti{
  width: 550px;
  margin:130px auto 164px;
  .wen-ti-title{
    text-align:center;
    margin-bottom:75px;
    font-size: 50px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #191919;
  }
  .collapse{
    width: 550px;
    // padding:15px 0 15px 15px;
    padding:15px 0;
    background: #FFFFFF;
    margin-top:10px;
    box-shadow: 20px 2px 20px 0px rgba(219, 219, 219, 0.5);
    font-size: 14px;
    font-family: AlibabaPuHuiTi-Light, AlibabaPuHuiTi;
    font-weight: 300;
    color: #191919;
    line-height: 24px;
  }
}
</style>
<style>
.el-collapse-item__header{
  font-size: 18px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #191919;
  line-height: 25px;
}
</style>
