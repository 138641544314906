<template>
  <div class="bar">
    <div class="chang-xiang" :class="index===0&&'blue'" @mouseenter="enter(0)" @mouseleave="leave(0)">
      <div v-show="index===0" class="chang-xiang-line" />
      <p class="fz32">畅享乐购</p>
      <span v-html="`无需“付全款”，分期计划，由你决定<br/>无需“付利息”，全程0息，没有套路<br/>线上申请，仅需两步，5分钟极速授信`" />

    </div>
    <div class="sui-xin" :class="index===1&&'blue'" @mouseenter="enter(1)" @mouseleave="leave(1)">
      <div v-show="index===1" class="sui-xin-line" />
      <p class="fz32">随心而动</p>
      <span v-html="`喜欢，无需等待<br/>行动，即刻拥有<br/>享受，没有负担`" />
    </div>
    <div class="gui-hua" :class="index===2&&'blue'" @mouseenter="enter(2)" @mouseleave="leave(2)">
      <p class="fz32">规划生活</p>
      <span v-html="`合理规划预算，重塑支付习惯 <br/>没有额外支出，所见即所付 <br/>少一点压力，多亿点品质`" />
      <div v-show="index===2" class="gui-hua-line" />
    </div>
    <div :class="['bar-img',{'rotate90': index===2}, {'rotate225': index===0}]" />
    <img src="../images/img_hua.png" class="hua-img">
  </div>
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
const index = ref(2)
const rotate = reactive(['rotate225', '', 'rotate90'])
const enter = (inx) => {
  index.value = inx
}
const leave = (inx) => {
  index.value = inx
}
const changeRotate = (inx) => {
  return rotate[inx]
}
</script>

<style lang='less' scoped>
p{
  margin:10px 0 !important;
}
.bar{
  position: relative;
  width: 1122px;
  height:555px;
  margin:0 auto 0;
  font-size: 18px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #191919;
  .blue{
    color:#4481FD;
  }
  .fz32{
    font-size:28px;
  }

  .bar-img{
    transition: all 0.3s;
    position: absolute;
    top:75px;
    left: 370px;
    width:447px;
    height:447px;
    background:url('../images/img_quan.png');
    background-size:100%;
    &.rotate90{
      transform:rotate(90deg);
    }
    &.rotate225{
      transform:rotate(225deg);
    }
  }
  .hua-img{
    position: absolute;
    top:220px;
    left:520px;
    height:10px;
    width:145px;
    height:145px;
  }
  .chang-xiang{
    position: absolute;
    top:250px;
    left:0;
    line-height:30px;
    .chang-xiang-line{
      position: absolute;
      left:30px;
      top:-75px;
      width: 350px;
      height: 79px;
      border-left: 1px solid #4481FD;
      border-top: 1px solid #4481FD;
    }
  }
  .sui-xin{
    position: absolute;
    top:0px;
    left:900px;
    line-height:30px;
    .sui-xin-line{
      position: absolute;
      left:-140px;
      top:20px;
      width: 124px;
      height: 79px;
      border-left: 1px solid #4481FD;
      border-top: 1px solid #4481FD;
    }
  }
  .gui-hua{
    position: absolute;
    bottom:0px;
    left:900px;
    line-height:30px;
    .gui-hua-line{
      position: absolute;
      left:-100px;
      top:28px;
      width: 90px;
      height: 79px;
      border-top: 1px solid #4481FD;
    }
  }
}
</style>
